<template>
  <b-card v-if="data" class="earnings-card">
    <b-row>
      <b-col cols="7">
        <b-card-title>Today's Collection</b-card-title>

        <b-card-text class="font-small-6 font-weight-bolder mb-0">
          <span>Rs.</span>
          <span class="font-weight-bolder mb-0" style="font-size: 20px">{{
            data.collection
          }}</span>
        </b-card-text>
        <b-card-text class="font-small-5 mb-3">
          Receipt - {{ data.collectionCount }}
        </b-card-text>
        <!-- <h4 class=" mb-"></h4> -->
        <b-button
          style="margin-top: auto"
          variant="primary"
          @click="viewReport()"
        >
          View Report
        </b-button>
      </b-col>
      <!-- style="border-left: 1px solid gray" -->
      <b-col cols="5" v-if="!hidechart">
        <!-- chart -->
        <b-card-title>Today's Collection</b-card-title>
        <vue-apex-charts
          height="120"
          :options="earningsChart.chartOptions"
          :series="monthlySeries"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $earningsStrokeColor2 = "#836AF9";
const $earningsStrokeColor3 = "#ff4961";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BButton,

    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    monthlyData: {
      type: Object,
      default: () => {},
    },
    monthlySeries: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    viewReport() {
      let date = new Date().toJSON().split("T")[0];
      // console.log(date);
      var url =
        `https://${this.reportDomain}.myskool.app/Fee/Show?type=dcr&dtFrom=` +
        date +
        "&dtTo=" +
        date +
        "&dbb=" +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId +
        "&format=Class wise";
      window.open(url, "_blank");
    },
  },
  data() {
    return {
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      hidechart: true,
      earningsChart: {
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          // comparedResult: [2, -3, 8],
          labels: ["Paid", "UnPaid"],
          stroke: { width: 0 },
          colors: [$themeColors.success, $themeColors.danger],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: this.monthlyData.month,
                    formatter() {
                      return "";
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    };
  },
  created() {
    // console.log(this.monthlySeries);
    // if (monthlySeries[0] == 0 || monthlySeries[1] == 0) {
    //   this.hidechart = true;
    // } else {
    //   this.hidechart = false;
    // }
  },
};
</script>
