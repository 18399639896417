<template>
  <b-card v-if="data" class="card-congratulation-medal">
    <h5>Welcome 🎉 {{ this.$store.state.userData.username }}!</h5>
    <b-card-text class="font-small-3"> Your collection report </b-card-text>
    <h3 class="mb-75 mt-2 pt-50">
      <b-link>${{ kFormatter(data.saleToday) }}</b-link>
    </h3>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="viewReport()"
    >
      View Report
    </b-button>
    <b-img
      :src="this.$store.state.logo.url"
      class="congratulation-medal"
      alt="Medal Pic"
      style="width: 100px; height: 100px; object-fit: cover"
    />
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink, BButton, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
    viewReport() {
      let date = new Date().toJSON().split("T")[0];
      // console.log(date);
      var url =
        "https://reports.myskool.app/Fee/Show?type=dcr&dtFrom=" +
        date +
        "&dtTo=" +
        date +
        "&dbb=" +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId;
      window.open(url, "_blank");
    },
  },
};
</script>
